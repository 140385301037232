import logo from "../raw/logoShortBlack.png";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HamburgerMenu from "./Ham";
function TopNav() {
  const location = useLocation();
  return (
    <div className="header_container">
      <div
        style={{
          width: "-webkit-fill-available",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        {window.innerWidth > 600 && (
          <div
            style={{
              display: "flex",
              borderRadius: "50vh",
              marginRight: "20px",
              padding: "10px 15px",
            }}
          >
            <Link
              to="/"
              className="tab_link"
              style={{
                fontSize: "17px",
                margin: "0px 13px",
                display: "flex",
                flexDirection: "column",
                color: `${
                  location.pathname === "/" ||
                  location.pathname === "/home" ||
                  location.pathname === "/class"
                    ? "#007bff"
                    : "#404040"
                }`,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Home
            </Link>
            <Link
              to="/#Services"
              className="tab_link"
              style={{
                fontSize: "17px",
                margin: "0px 13px",
                display: "flex",
                flexDirection: "column",
                color: `${
                  location.pathname === "/howitworks" ? "#007bff" : "#404040"
                }`,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Services
            </Link>
            <Link
              to="/#ourWork"
              className="tab_link"
              style={{
                fontSize: "17px",
                margin: "0px 13px",
                display: "flex",
                flexDirection: "column",
                color: `${
                  location.pathname === "/contact" ? "#007bff" : "#404040"
                }`,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              OurWork
            </Link>
            <Link
              to="/#aboutus"
              className="tab_link"
              style={{
                fontSize: "17px",
                margin: "0px 13px",
                display: "flex",
                flexDirection: "column",
                color: `${
                  location.pathname === "/blog" ? "#007bff" : "#404040"
                }`,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              About us
            </Link>
          </div>
        )}
        <Link
          style={{
            cursor: "pointer",
            maxHeight: "40px",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 0px)",
          }}
          to="/"
          className="logo"
        >
          <img src={logo} className="logo" style={{ maxHeight: "40px" }} />
        </Link>
        <div style={{ justifySelf: "right" }}>
          {window.innerWidth > 600 ? (
            <Link
              to="/#aboutus"
              className="tab_link"
              style={{
                fontSize: "17px",
                margin: "0px 13px",
                display: "flex",
                flexDirection: "column",
                color: `${
                  location.pathname === "/blog" ? "#007bff" : "#404040"
                }`,
                justifyContent: "center",
                alignItems: "center",
              }}
            ></Link>
          ) : (
            <HamburgerMenu />
          )}
        </div>
      </div>
    </div>
  );
}

export default TopNav;

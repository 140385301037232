import React from "react";
import send from "../raw/send.png";
import ask from "../raw/ask.png";
import { useForm, ValidationError } from "@formspree/react";
function ContactForm() {
  const [state, handleSubmit] = useForm("xpzgnorv");
  if (state.succeeded) {
    return (
      <h1 className="bigFlashy" style={{ color: "#000" }}>
        Thanks for reaching out!
      </h1>
    );
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <img src={ask} style={{ width: "30%" }} />
          <h3>Got any questions or need help?</h3>
          <div style={{ display: window.innerWidth > 600 && "flex" }}>
            <input
              className="contact_input"
              type={"text"}
              placeholder="First name"
              id="firstName"
              name="firstName"
            />
            <ValidationError
              prefix="firstName"
              field="firstName"
              errors={state.errors}
            />
            <input
              className="contact_input"
              type={"text"}
              placeholder="Last name"
              id="lastName"
              name="lastName"
            />
            <ValidationError
              prefix="lastName"
              field="lastName"
              errors={state.errors}
            />
          </div>
          <div style={{ display: window.innerWidth > 600 && "flex" }}>
            <input
              className="contact_input"
              type={"text"}
              placeholder="Email"
              id="email"
              name="email"
            />
            <ValidationError
              prefix="email"
              field="email"
              errors={state.errors}
            />
            <input
              className="contact_input"
              type={"text"}
              placeholder="Contact number"
              id="contactNumber"
              name="contactNumber"
            />
            <ValidationError
              prefix="contactNumber"
              field="contactNumber"
              errors={state.errors}
            />
          </div>
          <input
            className="contact_input"
            type={"text"}
            placeholder="Write your message here"
            id="message"
            name="message"
          />
        </div>
        <br></br>
        <br></br>
        <button
          type="submit"
          style={{
            width: "max-content",
            background: "#000",
            borderRadius: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFCF4A",
            fontSize: "1em",
            fontWeight: "800",
            padding: "10px 15px",
          }}
          className="submit_btn"
        >
          <img src={send} style={{ height: "40px", marginRight: "10px" }} />
          SEND
        </button>
      </form>
    </div>
  );
}

export default ContactForm;

import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import AddJobModal from "./components/AddJobMoadal";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "./Firebase/config";
import { Link } from "react-router-dom";
import { BiBriefcaseAlt2 } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { FaHourglassHalf } from "react-icons/fa";
import ApplicationsModal from "./components/ApplicationsModal";
import EditJobModal from "./components/EditJobModal";
import { set } from "firebase/database";
function Admin() {
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [allJobs, setAllJobs] = useState(null);
  useEffect(() => {
    onSnapshot(collection(db, "jobs"), (snap) => {
      setAllJobs(snap.docs.map((doc) => doc.data()));
    });
  }, []);
  useEffect(() => {
    if (isAdd) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isAdd]);
  function deleteJob(key) {
    const q = window.confirm("Do you really want to delete this job?");
    if (q) {
      deleteDoc(doc(db, "jobs", key)).then((res) => {
        alert("Deleted!");
      });
    }
  }
  const [selected, setSetselected] = useState(null);
  return (
    <div className="home_container">
      {isEdit && selected && (
        <EditJobModal
          setIsApply={setIsEdit}
          setSelected={setSetselected}
          jobId={selected.jobId}
        />
      )}
      {!isEdit && selected && (
        <ApplicationsModal selected={selected} setSelected={setSetselected} />
      )}
      {isAdd && <AddJobModal setIsApply={setIsAdd} />}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h1 className="bigFlashy">All Jobs</h1>
        <IoMdAdd
          onClick={() => {
            setIsAdd(true);
          }}
          className="submit_btn"
          style={{
            color: "#385efc",
            fontSize: "3em",
            background: "#FFCF4A",
            borderRadius: "50vh",
          }}
        />
      </div>
      {allJobs &&
        allJobs.map((data) => {
          return (
            <div
              style={{
                height: "max-content",
                minWidth: "220px",
                borderRadius: "10px",
                background: "#385EFC",
                margin: "15px",
                position: "relative",
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    background: "#FFCF4A",
                    width: "fit-content",
                    color: "#000",
                    padding: "5px 18px",
                    borderRadius: "50vh",
                  }}
                >
                  {data.jobId.slice(0, 5)}
                </div>
                <h2
                  style={{
                    fontSize: "1.5em",
                    marginBottom: "10px",
                    color: "#fff",
                    width: "400px",
                  }}
                >
                  {data.title}
                </h2>
              </div>
              <div style={{ marginRight: "40px" }}>
                <h4 style={{ color: "#fff" }}>
                  <BiBriefcaseAlt2 style={{ marginRight: "10px" }} />
                  {data?.category}
                </h4>
                <h4 style={{ color: "#fff" }}>
                  <HiLocationMarker style={{ marginRight: "10px" }} />
                  {data?.location}
                </h4>
              </div>
              <div>
                <h4 style={{ color: "#fff" }}>
                  <BsFillCalendarWeekFill style={{ marginRight: "10px" }} />
                  {data?.exp}
                </h4>
                <h4 style={{ color: "#fff" }}>
                  <FaHourglassHalf style={{ marginRight: "10px" }} />
                  {data?.closeDate}
                </h4>
              </div>
              <div>
                <div
                  style={{
                    height: "40px",
                    width: "150px",
                    background: "#000",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#FFCF4A",
                    fontSize: "1em",
                    fontWeight: "1000",
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    setSetselected(data);
                  }}
                >
                  Applications
                </div>
                <div
                  style={{
                    height: "40px",
                    width: "150px",
                    background: "#000",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#FFCF4A",
                    fontSize: "1em",
                    fontWeight: "1000",
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    setSetselected(data);
                    setIsEdit(true);
                  }}
                >
                  Edit Job
                </div>
              </div>
              <div>
                <div
                  style={{
                    height: "40px",
                    width: "150px",
                    background: "#000",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#FFCF4A",
                    fontSize: "1em",
                    fontWeight: "1000",
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    deleteJob(data.jobId);
                  }}
                >
                  Delete
                </div>
                <Link to={`/job?${data.jobId}`}>
                  <div
                    style={{
                      height: "40px",
                      width: "150px",
                      background: "#000",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#FFCF4A",
                      fontSize: "1em",
                      fontWeight: "1000",
                    }}
                  >
                    View
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default Admin;

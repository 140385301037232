import React, { useState } from "react";
import fajyl from "./raw/fajyl.png";
import search from "./raw/search.png";
import { Link } from "react-router-dom";
import { BiBriefcaseAlt2 } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { FaHourglassHalf } from "react-icons/fa";
import { useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./Firebase/config";

function AllJobs() {
  const [allJobs, setAllJobs] = useState(null);
  const [filteredJobs, setFilteredJobs] = useState(null);
  const [jobType, setJobType] = useState("");
  const [location, setLocation] = useState("");
  const [contractType, setContractType] = useState("");
  const [Keyword, setKeyword] = useState("");
  useEffect(() => {
    if (filteredJobs) {
      const filteredItems = allJobs.filter((item) => {
        return (
          (Keyword !== ""
            ? item.title.toLowerCase().includes(Keyword.toLowerCase())
            : true) &&
          (jobType !== "" ? item.jobType === jobType : true) &&
          (location !== ""
            ? item.location.toLowerCase() === location.toLowerCase()
            : true) &&
          (contractType !== "" ? item.category === contractType : true)
        );
      });
      setFilteredJobs(filteredItems);
    }
  }, [jobType, location, contractType, Keyword]);

  useEffect(() => {
    onSnapshot(collection(db, "jobs"), (snap) => {
      setAllJobs(snap.docs.map((doc) => doc.data()));
      setFilteredJobs(snap.docs.map((doc) => doc.data()));
    });
  }, []);
  return (
    <div className="home_container" style={{ paddingTop: "5px" }}>
      <div className="hero" style={{ position: "relative" }}>
        <img src={fajyl} style={{ width: "100%" }} />
        <img
          src={search}
          style={{
            width: "100px",
            position: "absolute",
            left: "30%",
            bottom: "0",
            transform: "translate(-50%, 50%)",
          }}
        />
      </div>

      <h1 className="bigFlashy" style={{ marginTop: "50px" }}>
        ALL JOBS
      </h1>
      <div
        style={{
          display: window.innerWidth > 600 ? "flex" : "block",
        }}
      >
        <select
          className="filter_input"
          style={{ flex: "1", color: "#6d6d6d", position: "relative" }}
          placeholder="Job Type"
          value={jobType}
          onChange={(e) => {
            setJobType(e.target.value);
          }}
        >
          <option
            value={""}
            onClick={() => {
              setJobType("");
            }}
          >
            Job Type
          </option>
          <option>QA</option>
          <option>Development</option>
          <option>Management</option>
          <option>Data</option>
          <option>Security</option>
        </select>
        <select
          className="filter_input"
          style={{ flex: "1", color: "#6d6d6d" }}
          placeholder="Location"
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
          }}
        >
          <option
            value={""}
            onClick={() => {
              setLocation("");
            }}
          >
            Select Location
          </option>
          {allJobs &&
            [...new Set(allJobs.map((job) => job.location.toLowerCase()))].map(
              (job) => {
                return <option>{job}</option>;
              }
            )}
        </select>
        <select
          className="filter_input"
          style={{ flex: "1", color: "#6d6d6d" }}
          placeholder="Contract type"
          value={contractType}
          onChange={(e) => {
            setContractType(e.target.value);
          }}
        >
          <option
            value={""}
            onClick={() => {
              setContractType("");
            }}
          >
            Contract type
          </option>
          <option>Permanent</option>
          <option>Outsourcing</option>
          <option>Part time</option>
        </select>
        <input
          className="filter_input"
          style={{ flex: "1" }}
          type={"text"}
          placeholder="Keyword"
          value={Keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
        />
      </div>
      <br></br>
      {filteredJobs &&
        filteredJobs.map((data) => {
          return (
            <div
              style={{
                height: "max-content",
                minWidth:
                  window.innerWidth > 600 ? "220px" : "-webkit-fill-available",
                borderRadius: "10px",
                background: "#385EFC",
                margin: "15px",
                position: "relative",
                padding: "20px",
                display: window.innerWidth > 600 && "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    background: "#FFCF4A",
                    width: "fit-content",
                    color: "#000",
                    padding: "5px 18px",
                    borderRadius: "50vh",
                  }}
                >
                  {data.jobId.slice(0, 5)}
                </div>
                <h2
                  style={{
                    fontSize: "1.5em",
                    marginBottom: "10px",
                    color: "#fff",
                    minWidth: window.innerWidth > 600 && "400px",
                  }}
                >
                  {data.title}
                </h2>
              </div>
              <div style={{ marginRight: "40px" }}>
                <h4 style={{ color: "#fff" }}>
                  <BiBriefcaseAlt2 style={{ marginRight: "10px" }} />
                  {data?.category}
                </h4>
                <h4 style={{ color: "#fff" }}>
                  <HiLocationMarker style={{ marginRight: "10px" }} />
                  {data?.location}
                </h4>
              </div>
              <div>
                <h4 style={{ color: "#fff" }}>
                  <BsFillCalendarWeekFill style={{ marginRight: "10px" }} />
                  {data?.exp}
                </h4>
                <h4 style={{ color: "#fff" }}>
                  <FaHourglassHalf style={{ marginRight: "10px" }} />
                  {data?.closeDate}
                </h4>
              </div>
              <div>
                <Link to={`/job?${data.jobId}`}>
                  <div
                    style={{
                      height: "50px",
                      width: "150px",
                      background: "#000",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#FFCF4A",
                      fontSize: "1em",
                      fontWeight: "1000",
                    }}
                  >
                    More Info
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default AllJobs;

import React from 'react'
import { IoMdClose } from 'react-icons/io';

function About({ setIsAboutVisible }) {
    return (
        <div className='home_container' style={{ overflow: 'scroll' }}>
            <IoMdClose onClick={() => { setIsAboutVisible(false) }} style={{ color: '#004aad', background: '#ccc', borderRadius: '50vh', padding: '10px', fontSize: '2em', margin: '20px', position: 'absolute', top: '100px', right: '0' }} />
            <h1 style={{ marginTop: '0', color: '#004aad' }}>Berkeley match</h1>
            About content will go here
        </div>
    )
}

export default About
import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, database, db } from "./Firebase/config";
import heading from "./raw/Recruitment.png";
import topImage from "./raw/topImage.png";
import { FaFacebookF } from "react-icons/fa";
import bs from "./raw/bs.png";
import git from "./raw/getintouch.png";
import wu from "./raw/whyus.png";
import lfnp from "./raw/lfnp.png";
import man from "./raw/man.png";
import dog from "./raw/dog.png";
import { LoveToWorkWith } from "./components/Tapes";
import { Link } from "react-router-dom";
import { collection, onSnapshot } from "firebase/firestore";
import ContactForm from "./components/ContactForm";

import { GrLinkedinOption } from "react-icons/gr";
import { BsInstagram } from "react-icons/bs";
import b1 from "./raw/b1.png";
import b2 from "./raw/b2.png";
import b3 from "./raw/b3.png";
import b4 from "./raw/b4.png";
function HomePage() {
  const [user] = useAuthState(auth);
  const [allJobs, setAllJobs] = useState(null);
  useEffect(() => {
    onSnapshot(collection(db, "jobs"), (snap) => {
      setAllJobs(snap.docs.map((doc) => doc.data()));
    });
  }, []);
  if (!allJobs) {
    return (
      <div className="home_container">
        <h1 style={{ width: "calc(100vw - 40px)", textAlign: "center" }}>
          Loading
        </h1>
      </div>
    );
  }
  return (
    <div className="home_container" style={{ paddingTop: "5px" }}>
      <div className="hero">
        <img src={heading} style={{ width: "100%" }} />
        <div
          style={{
            display: "flex",
            flexDirection: window.innerWidth > 600 ? "row" : "column",
          }}
        >
          <div style={{ flex: "0.5", position: "relative" }}>
            <img src={topImage} style={{ height: "100%", width: "100%" }} />
            <img
              src={git}
              style={{
                height: "100px",
                width: "100px",
                position: "absolute",
                top: window.innerWidth > 600 ? "40%" : "70%",
                right: window.innerWidth > 600 ? "-8%" : "0px",
              }}
              className="rotating-div"
              onClick={() => {
                document
                  .getElementById("contact-us")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            />
          </div>
          <div
            style={{
              flex: "0.5",
              padding: window.innerWidth > 600 ? "0px 30px" : "30px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <img src={bs} style={{ width: "100%" }} />

            <p style={{ color: "#fff", marginBottom: "0", fontSize: "1.4em" }}>
              We're not your typical agency – we're here to cut through the
              fluff and connect talented specialists with the opportunities they
              truly deserve. Say goodbye to the traditional recruitment jargon –
              we're straightforward, transparent, and all about making IT happen
              for you.
            </p>
          </div>
        </div>
      </div>

      <div className="services" id="Services" style={{ paddingTop: "65px" }}>
        <center>
          <h4 style={{ color: "#fff", maxWidth: "800px", fontSize: "1.5em" }}>
            Welcome to our no-nonsense IT recruitment agency.
          </h4>
          <h1 style={{ color: "#fff", maxWidth: "800px", fontSize: "1.4em" }}>
            Our focus is on delivering real results without the Bullshit. With a
            passion for all things tech, we're dedicated to matching you with
            companies where your skills will shine and your growth will
            flourish. Join us today and let's build your future, minus the
            nonsense.
          </h1>
        </center>
      </div>

      <div className="whyus" id="whyus" style={{ paddingTop: "65px" }}>
        <img src={wu} style={{ width: "100%" }} />
        <div
          style={{
            display: "flex",
            flexDirection: window.innerWidth > 600 ? "row" : "column",
          }}
        >
          <div
            style={{
              background: "#385EFC",
              flex: "0.6",
              minHeight: "220px",
              margin: "10px",
              borderRadius: "10px",
              padding: "0px 20px",
              position: "relative",
            }}
          >
            <h1
              style={{
                color: "#fff",
                fontWeight: "1000",
                fontSize: "3em",
                fontFamily: "thunder",
                textTransform: "uppercase",
              }}
            >
              Permanent<br></br> placement
            </h1>
            <h4 style={{ color: "#fff", fontSize: "1.1em" }}>
              Our successful track record in permanent placements demonstrates
              why we are a recruitment partner for many leading companies in
              Europe, in fields such as backend, frontend, data or security
            </h4>
            <img
              src={b4}
              style={{
                position: "absolute",
                right: "0",
                bottom: "20px",
                zIndex: "999",
                height: "200px",
              }}
            />
          </div>
          <div
            style={{
              background: "#385EFC",
              flex: "0.4",
              minHeight: "220px",
              margin: "10px",
              borderRadius: "10px",
              padding: "0px 20px",
              position: "relative",
            }}
          >
            <h1
              style={{
                color: "#fff",
                fontWeight: "1000",
                fontSize: "3em",
                fontFamily: "thunder",
                textTransform: "uppercase",
              }}
            >
              Temporary staff<br></br> recruitment
            </h1>
            <h4 style={{ color: "#fff", fontSize: "1.1em" }}>
              Whether you need to satisfy surges in demand, staff absences or
              time-limited project goals, we will help you to hire the person
              who can support short-term business objectives. Temporary
              recruitment provides efficiency and strategic advantages, when you
              can’t or don’t need to absorb a staff member full-time.
            </h4>
            <img
              src={b3}
              style={{
                position: "absolute",
                right: "0",
                bottom: "20px",
                zIndex: "999",
                height: "200px",
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: window.innerWidth > 600 ? "row" : "column",
          }}
        >
          <div
            style={{
              background: "#385EFC",
              flex: "0.4",
              minHeight: "220px",
              margin: "10px",
              borderRadius: "10px",
              padding: "0px 20px",
              position: "relative",
            }}
          >
            <h1
              style={{
                color: "#fff",
                fontWeight: "1000",
                fontSize: "3em",
                fontFamily: "thunder",
                textTransform: "uppercase",
              }}
            >
              Outsourcing
            </h1>
            <h4 style={{ color: "#fff", fontSize: "1.1em" }}>
              We lead the entire recruitment process, operating efficiently to
              find highly-qualified people who will contribute to your success.
              We spend time getting to know your company, your hiring manager
              and your teams, to understand your company values and cultural
              aspects of your employees. We have experience in build whole teams
              and finding very niche specialist
            </h4>
            <img
              src={b1}
              style={{
                position: "absolute",
                right: "0",
                bottom: "20px",
                zIndex: "999",
                height: "200px",
              }}
            />
          </div>
          <div
            style={{
              background: "#385EFC",
              flex: "0.6",
              minHeight: "220px",
              margin: "10px",
              borderRadius: "10px",
              padding: "0px 20px",
              position: "relative",
            }}
          >
            <h1
              style={{
                color: "#fff",
                fontWeight: "1000",
                fontSize: "3em",
                fontFamily: "thunder",
                textTransform: "uppercase",
              }}
            >
              RPO
            </h1>
            <h4 style={{ color: "#fff", fontSize: "1.1em" }}>
              Do you have recruitment projects and need recruiters to support
              you exlusively? We got you! We can offer you team of recruiters
              that will work only for you and only with you. One project or only
              for some time? No worries we are flexible:) In addition to a
              dedicated recruiter, you have the support of all the digital
              peoples and our talent pool. With us, there are no impossible
              projects.
            </h4>
            <img
              src={b2}
              style={{
                position: "absolute",
                right: "0",
                bottom: "20px",
                zIndex: "999",
                height: "200px",
              }}
            />
          </div>
        </div>
      </div>

      <div className="projects" id="projects" style={{ paddingTop: "65px" }}>
        <img src={lfnp} style={{ width: "100%" }} />
        <div
          style={{ display: "flex", overflow: "scroll", margin: "40px 0px" }}
        >
          {allJobs &&
            allJobs.map((data) => {
              return (
                <div
                  style={{
                    height: "200px",
                    minWidth: "220px",
                    borderRadius: "10px",
                    background: "#fff",
                    margin: "15px",
                    position: "relative",
                    padding: "15px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div
                      style={{
                        background: "#FFCF4A",
                        width: "fit-content",
                        color: "#000",
                        padding: "5px 18px",
                        borderRadius: "50vh",
                      }}
                    >
                      {data.jobId.slice(0, 4)}
                    </div>
                    <h2 style={{ fontSize: "1.3em", marginBottom: "10px" }}>
                      {data.title}
                    </h2>
                    <h4 style={{ margin: "0" }}>Contract in {data.location}</h4>
                  </div>
                  <Link to={`/job?${data.jobId}`}>
                    <h5 style={{ margin: "0" }}>Learn more</h5>
                  </Link>
                </div>
              );
            })}
        </div>
        <center>
          <Link to="/alljobs">
            <div
              style={{
                height: window.innerWidth < 600 ? "100px" : "180px",
                width: "calc(100% - 10%)",
                background: "#FFCF4A",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#000",
                fontSize: window.innerWidth > 600 ? "5em" : "2em",
                fontWeight: "1000",
              }}
            >
              VIEW ALL JOBS
            </div>
          </Link>
        </center>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="aboutus" id="aboutus" style={{ paddingTop: "65px" }}>
        <LoveToWorkWith />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div
          style={{
            display: "flex",
            flexDirection: window.innerWidth > 600 ? "row" : "column",
            alignContent: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              flex: "0.3",
              position: "relative",

              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <img src={man} style={{ width: "100%" }} />
            <center>
              <h2 style={{ color: "#fff", marginBottom: "0" }}>
                Michał Doliński{" "}
              </h2>
              <p style={{ color: "#ccc", marginTop: "8px" }}>
                CEO and lead recruiter
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a
                  href="https://www.linkedin.com/in/michaldolinski/"
                  target="_blank"
                  style={{
                    background: "#ffcf4a",
                    padding: "10px",
                    borderRadius: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    maxWidth: "min-content",
                  }}
                >
                  <GrLinkedinOption
                    style={{
                      color: "#000",
                      fontSize: "1.4em",
                      borderRadius: "0",
                    }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/digitalpplagency"
                  target="_blank"
                  style={{
                    background: "#ffcf4a",
                    padding: "10px",
                    borderRadius: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    maxWidth: "min-content",
                  }}
                >
                  <BsInstagram
                    style={{
                      color: "#000",
                      fontSize: "1.4em",
                      borderRadius: "0",
                    }}
                  />
                </a>
                <a
                  style={{
                    background: "#ffcf4a",
                    padding: "10px",
                    borderRadius: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    maxWidth: "min-content",
                  }}
                >
                  <FaFacebookF
                    style={{
                      color: "#000",
                      fontSize: "1.4em",
                      borderRadius: "0",
                    }}
                  />
                </a>
              </div>
            </center>
          </div>
          <div
            style={{
              flex: "0.3",
              position: "relative",

              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <img src={dog} style={{ width: "100%" }} />
            <center>
              <h2 style={{ color: "#fff", marginBottom: "0" }}>Kluska </h2>
              <p style={{ color: "#ccc", marginTop: "8px" }}>
                Chief happines officer
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a
                  href="https://www.instagram.com/digitalpplagency"
                  target="_blank"
                  style={{
                    background: "#ffcf4a",
                    padding: "10px",
                    borderRadius: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    maxWidth: "min-content",
                  }}
                >
                  <BsInstagram
                    style={{
                      color: "#000",
                      fontSize: "1.4em",
                      borderRadius: "0",
                    }}
                  />
                </a>
                <a
                  style={{
                    background: "#ffcf4a",
                    padding: "10px",
                    borderRadius: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    maxWidth: "min-content",
                  }}
                >
                  <FaFacebookF
                    style={{
                      color: "#000",
                      fontSize: "1.4em",
                      borderRadius: "0",
                    }}
                  />
                </a>
              </div>
            </center>
          </div>
        </div>
      </div>

      <div
        className="contact us"
        id="contactus"
        style={{ paddingTop: "65px", position: "relative" }}
      >
        <img
          src={git}
          id="contact-us"
          style={{
            height: "100px",
            width: "100px",
            position: "absolute",
            top: "0%",
            right: "50%",
            transform: "translate(50%, 10px)",
          }}
        />
        <center>
          <div
            style={{
              width: window.innerWidth > 600 ? "60%" : "calc(100% - 60px)",
              height: "max-content",
              borderRadius: "20px",
              background: "#FFCF4A",
              padding: window.innerWidth > 600 ? "80px 50px" : "80px 30px",
            }}
          >
            <ContactForm />
          </div>
        </center>
      </div>
      <br></br>
    </div>
  );
}

export default HomePage;

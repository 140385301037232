import SkewedTape from "./SkewedTape";
import Marquee from "react-fast-marquee";
import blackstar from "../raw/blackstar.png";
export const LoveToWorkWith = (props) => {
  return (
    <>
      <div className="skewed-tape-wrapper">
        <SkewedTape angle="5deg">
          <Marquee speed={50} gradient={false} direction="right">
            <h1 className="skewed-tape-text">
              WE ARE THE TEAM THAT YOU WILL LOVE TO WORK WITH
            </h1>
            <img className="skewed-tape-img" src={blackstar} alt="" />
            <h1 className="skewed-tape-text">
              WE ARE THE TEAM THAT YOU WILL LOVE TO WORK WITH
            </h1>
            <img className="skewed-tape-img" src={blackstar} alt="" />
            <h1 className="skewed-tape-text">
              WE ARE THE TEAM THAT YOU WILL LOVE TO WORK WITH
            </h1>
            <img className="skewed-tape-img" src={blackstar} alt="" />
            <h1 className="skewed-tape-text">
              WE ARE THE TEAM THAT YOU WILL LOVE TO WORK WITH
            </h1>
            <img className="skewed-tape-img" src={blackstar} alt="" />
            <h1 className="skewed-tape-text">
              WE ARE THE TEAM THAT YOU WILL LOVE TO WORK WITH
            </h1>
            <img className="skewed-tape-img" src={blackstar} alt="" />
          </Marquee>
        </SkewedTape>
        <SkewedTape angle="-5deg">
          <Marquee speed={50} gradient={false}>
            <h1 className="skewed-tape-text">
              WE ARE THE TEAM THAT YOU WILL LOVE TO WORK WITH
            </h1>
            <img className="skewed-tape-img" src={blackstar} alt="" />
            <h1 className="skewed-tape-text">
              WE ARE THE TEAM THAT YOU WILL LOVE TO WORK WITH
            </h1>
            <img className="skewed-tape-img" src={blackstar} alt="" />
            <h1 className="skewed-tape-text">
              WE ARE THE TEAM THAT YOU WILL LOVE TO WORK WITH
            </h1>
            <img className="skewed-tape-img" src={blackstar} alt="" />
            <h1 className="skewed-tape-text">
              WE ARE THE TEAM THAT YOU WILL LOVE TO WORK WITH
            </h1>
            <img className="skewed-tape-img" src={blackstar} alt="" />
            <h1 className="skewed-tape-text">
              WE ARE THE TEAM THAT YOU WILL LOVE TO WORK WITH
            </h1>
            <img className="skewed-tape-img" src={blackstar} alt="" />
          </Marquee>
        </SkewedTape>
      </div>
    </>
  );
};

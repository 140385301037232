import logo from "../raw/logoshort.png";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GrLinkedinOption } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import HamburgerMenu from "./Ham";
function TopNav() {
  const location = useLocation();
  return (
    <div
      className="header_container"
      style={{ backgroundColor: "#000", height: "max-content" }}
    >
      <div
        style={{
          width: "-webkit-fill-available",
          height: "100%",
          backgroundColor: "#000",
        }}
      >
        <div
          style={{
            width: "-webkit-fill-available",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <img
            style={{
              cursor: "pointer",
              height: "40px",
            }}
            src={logo}
          />
          <div style={{ display: "flex", marginBottom: "5px" }}>
            <a
              href="https://www.linkedin.com/company/digitalpplagency/"
              target="_blank"
              style={{
                background: "#385EFC",
                padding: "10px",
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <GrLinkedinOption
                style={{
                  color: "#fff",
                  fontSize: "1.5em",
                  borderRadius: "0",
                }}
              />
            </a>
            <a
              href="https://www.instagram.com/digitalpplagency"
              target="_blank"
              style={{
                background: "#385EFC",
                padding: "10px",
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <BsInstagram
                style={{
                  color: "#fff",
                  fontSize: "1.5em",
                  borderRadius: "0",
                }}
              />
            </a>
            <a
              style={{
                background: "#385EFC",
                padding: "10px",
                borderRadius: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <FaFacebookF
                style={{
                  color: "#fff",
                  fontSize: "1.5em",
                  borderRadius: "0",
                }}
              />
            </a>
          </div>
        </div>

        <hr></hr>
        <div
          style={{
            display: window.innerWidth > 600 && "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <p style={{ color: "#fff" }}>
            All rights reserved by Digital people, 2023. created with love by
            Kush Padhiyar
          </p>
          <div style={{ display: "flex" }}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/stock-app-2a74c.appspot.com/o/privacy%20policy.pdf?alt=media&token=4bc817db-2396-4c44-b479-2d4374bab597&_gl=1*6cu8wp*_ga*OTEyNzUwMTUxLjE2ODc5MzYwMzg.*_ga_CW55HF8NVT*MTY5ODk4OTYyNi4xMDAuMS4xNjk4OTg5NzE3LjQ3LjAuMA.."
              target="_blank"
              style={{ color: "#fff", marginRight: "10px" }}
            >
              Terms & conditions
            </a>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/stock-app-2a74c.appspot.com/o/privacy%20policy.pdf?alt=media&token=4bc817db-2396-4c44-b479-2d4374bab597&_gl=1*6cu8wp*_ga*OTEyNzUwMTUxLjE2ODc5MzYwMzg.*_ga_CW55HF8NVT*MTY5ODk4OTYyNi4xMDAuMS4xNjk4OTg5NzE3LjQ3LjAuMA.."
              target="_blank"
              style={{ color: "#fff", margin: "0px 10px" }}
            >
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopNav;

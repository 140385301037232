import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { v4 } from "uuid";
import { db, storage } from "../Firebase/config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

function ApplyModal({ setIsApply, jobId }) {
  const [agree, setAgree] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [enteredData, setEnteredData] = useState({
    fname: "",
    lname: "",
    qualification: "",
    pastexp: "",
    contactno: "",
    email: "",
    proposal: "",
    file: "",
  });
  function uploadPDF() {
    setFileLoading(true);
    const fileInput = document.getElementById("fileInput");
    const selectedFile = fileInput.files[0];

    if (!selectedFile) {
      console.log("No file selected");
      setFileLoading(false);
      return;
    }

    const storageRef = ref(storage, "pdfs/" + selectedFile.name); // Create a reference to a unique location in Firebase Storage

    // Upload the file to Firebase Storage
    uploadBytes(storageRef, selectedFile)
      .then((snapshot) => {
        console.log("File uploaded successfully");

        // Get the download URL of the uploaded file
        getDownloadURL(storageRef)
          .then((downloadURL) => {
            setEnteredData({ ...enteredData, file: downloadURL });
            setFileLoading(false);
          })
          .catch((error) => {
            console.error("Error getting download URL: ", error);
            alert("Error while uploading the CV, Try again later!");
            setFileLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error uploading file: ", error);
        alert("Error while uploading the CV, Try again later!");
        setFileLoading(false);
      });
  }
  function handleSubmit() {
    if (enteredData.fname !== "" && enteredData.contactno !== "") {
      const applicationId = v4();
      setDoc(doc(db, "applications", applicationId), {
        ...enteredData,
        applicationId,
        jobId,
      }).then(() => {
        alert("Application submitted!");
        setIsApply(false);
      });
    }
  }
  return (
    <div
      className="apply_modal_container"
      style={{ overflow: "scroll" }}
      onClick={() => {
        setIsApply(false);
      }}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: window.innerWidth < 600 ? "80%" : "60%",
            height: "max-content",
            borderRadius: "20px",
            background: "#FFCF4A",
            marginTop: "30px",
            padding: window.innerWidth < 600 ? "30px 20px" : "80px 50px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h3
            className="bigFlashy"
            style={{
              color: "#000",
              fontSize: window.innerWidth < 600 && "2em",
            }}
          >
            Enter Following details to apply
          </h3>
          <div>
            <div style={{ display: window.innerWidth > 600 && "flex" }}>
              <input
                className="contact_input"
                type={"text"}
                placeholder="First name"
                value={enteredData.fname}
                onChange={(e) => {
                  setEnteredData({ ...enteredData, fname: e.target.value });
                }}
              />
              <input
                className="contact_input"
                type={"text"}
                placeholder="Last name"
                value={enteredData.lname}
                onChange={(e) => {
                  setEnteredData({ ...enteredData, lname: e.target.value });
                }}
              />
            </div>
            <div style={{ display: window.innerWidth > 600 && "flex" }}>
              <input
                className="contact_input"
                type={"text"}
                placeholder="Email"
                value={enteredData.email}
                onChange={(e) => {
                  setEnteredData({ ...enteredData, email: e.target.value });
                }}
              />
              <input
                className="contact_input"
                type={"text"}
                placeholder="Contact number"
                value={enteredData.contactno}
                onChange={(e) => {
                  setEnteredData({ ...enteredData, contactno: e.target.value });
                }}
              />
            </div>
            <div style={{ display: window.innerWidth > 600 && "flex" }}>
              <input
                className="contact_input"
                type={"text"}
                placeholder="Past experience"
                value={enteredData.pastexp}
                onChange={(e) => {
                  setEnteredData({ ...enteredData, pastexp: e.target.value });
                }}
              />
            </div>
            <input
              className="contact_input"
              type={"text"}
              placeholder="Write your a short proposal"
              value={enteredData.proposal}
              onChange={(e) => {
                setEnteredData({ ...enteredData, proposal: e.target.value });
              }}
            />
            <div
              style={{
                display: window.innerWidth > 600 && "flex",
                alignItems: "center",
              }}
            >
              <input
                className="contact_input"
                type={"file"}
                id="fileInput"
                placeholder="Attach your CV"
                onChange={(e) => {
                  uploadPDF(e);
                }}
              />
              {fileLoading && (
                <p style={{ minWidth: "max-content", color: "red" }}>
                  Uploading your CV
                </p>
              )}
              {enteredData.file !== "" && (
                <p style={{ minWidth: "max-content", color: "green" }}>
                  CV Uploaded
                </p>
              )}
            </div>
          </div>
          <br></br>
          <input
            id="agree"
            type="checkbox"
            onChange={(e) => {
              setAgree(e.target.checked);
            }}
          ></input>
          <label htmlFor="agree"> I agree to terms & conditions</label>
          <br></br>
          <br></br>
          <div style={{ display: "flex" }}>
            <div
              style={{
                height: "50px",
                width: "150px",
                background: "#000",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFCF4A",
                fontSize: "1em",
                fontWeight: "1000",
                marginRight: "10px",
              }}
              onClick={() => {
                if (!agree) {
                  alert(
                    "In order to submit you need to accept our \nTerms and Conditions\nPrivacy policy\n"
                  );
                } else {
                  handleSubmit();
                }
              }}
              className="submit_btn"
            >
              Submit
            </div>
            <div
              style={{
                height: "50px",
                width: "150px",
                background: "#000",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFCF4A",
                fontSize: "1em",
                fontWeight: "1000",
              }}
              className="submit_btn"
              onClick={() => setIsApply(false)}
            >
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplyModal;

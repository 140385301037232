import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { v4 } from "uuid";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../Firebase/config";
import { useEffect } from "react";
function EditJobModal({ setIsApply, jobId, setSelected }) {
  const [enteredData, setEnteredData] = useState({
    title: "",
    desc: "",
    location: "",
    category: "",
    exp: "",
    closeDate: "",
    aboutCompany: "",
    jobType: "",
  });
  const [qual, setQual] = useState([]);
  const [resp, setResp] = useState([]);

  const qualRef = useRef(null);
  const respRef = useRef(null);

  useEffect(() => {
    onSnapshot(doc(db, "jobs", jobId), (snap) => {
      setEnteredData(snap.data());
      setQual(snap.data().qual);
      setResp(snap.data().resp);
    });
  }, []);

  function handleSubmit() {
    if (enteredData.title !== "") {
      setDoc(doc(db, "jobs", jobId), {
        ...enteredData,
        qual,
        resp,
      }).then(() => {
        alert("New Job added");
        setIsApply(false);
        setSelected(null);
      });
    }
  }
  return (
    <div
      className="apply_modal_container"
      style={{ overflow: "scroll" }}
      onClick={() => {
        setIsApply(false);
        setSelected(null);
      }}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "60%",
            height: "max-content",
            borderRadius: "20px",
            background: "#FFCF4A",
            padding: "80px 50px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h3 className="bigFlashy" style={{ color: "#000" }}>
            Edit Details
          </h3>
          <div>
            <div style={{ display: window.innerWidth > 600 && "flex" }}>
              <input
                className="contact_input"
                type={"text"}
                placeholder="Job title"
                value={enteredData.title}
                onChange={(e) => {
                  setEnteredData({ ...enteredData, title: e.target.value });
                }}
              />
              <input
                className="contact_input"
                type={"text"}
                placeholder="Short description"
                value={enteredData.desc}
                onChange={(e) => {
                  setEnteredData({ ...enteredData, desc: e.target.value });
                }}
              />
            </div>
            <div style={{ display: window.innerWidth > 600 && "flex" }}>
              <input
                className="contact_input"
                type={"text"}
                placeholder="Job location"
                value={enteredData.location}
                onChange={(e) => {
                  setEnteredData({
                    ...enteredData,
                    location: e.target.value,
                  });
                }}
              />
              <select
                className="contact_input"
                style={{ color: "#6d6d6d" }}
                placeholder="Contract type"
                value={enteredData.category}
                onChange={(e) => {
                  setEnteredData({
                    ...enteredData,
                    category: e.target.value,
                  });
                }}
              >
                <option>Contract type</option>
                <option>Permanent</option>
                <option>Outsourcing</option>
                <option>Part time</option>
              </select>
            </div>
            <div style={{ display: window.innerWidth > 600 && "flex" }}>
              <input
                className="contact_input"
                type={"text"}
                placeholder="Experience"
                value={enteredData.exp}
                onChange={(e) => {
                  setEnteredData({ ...enteredData, exp: e.target.value });
                }}
              />
              <input
                className="contact_input"
                type={"date"}
                placeholder="Application close date"
                value={enteredData.closeDate}
                onChange={(e) => {
                  setEnteredData({
                    ...enteredData,
                    closeDate: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <br></br>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              className="contact_input"
              type={"text"}
              placeholder="Qualification"
              ref={qualRef}
            />
            <div
              style={{
                height: "50px",
                width: "50px",
                background: "#000",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFCF4A",
                fontSize: "1em",
                fontWeight: "100",
                marginRight: "10px",
              }}
              onClick={() => {
                setQual([...qual, qualRef.current.value]);
                qualRef.current.value = "";
              }}
            >
              ADD
            </div>
          </div>
          <ul>
            {qual.map((text, i) => {
              return (
                <li style={{ color: "#000" }}>
                  {text}{" "}
                  <font
                    style={{ marginLeft: "10px", color: "red" }}
                    onClick={() => {
                      setQual(qual.filter((item) => item !== text));
                    }}
                  >
                    Delete
                  </font>
                </li>
              );
            })}
          </ul>
          <br></br>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              className="contact_input"
              type={"text"}
              placeholder="Responsibility"
              ref={respRef}
            />
            <div
              style={{
                height: "50px",
                width: "50px",
                background: "#000",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFCF4A",
                fontSize: "1em",
                fontWeight: "100",
                marginRight: "10px",
              }}
              onClick={() => {
                setResp([...resp, respRef.current.value]);
                respRef.current.value = "";
              }}
            >
              ADD
            </div>
          </div>
          <ul>
            {resp.map((text) => {
              return (
                <li style={{ color: "#000" }}>
                  {text}{" "}
                  <font
                    style={{ marginLeft: "10px", color: "red" }}
                    onClick={() => {
                      setResp(resp.filter((item) => item !== text));
                    }}
                  >
                    Delete
                  </font>
                </li>
              );
            })}
          </ul>
          <div style={{ display: window.innerWidth > 600 && "flex" }}>
            <select
              className="contact_input"
              style={{ flex: "1", color: "#6d6d6d" }}
              placeholder="Job Type"
              value={enteredData.jobType}
              onChange={(e) => {
                setEnteredData({
                  ...enteredData,
                  jobType: e.target.value,
                });
              }}
            >
              <option>Job Type</option>
              <option>QA</option>
              <option>Development</option>
              <option>Management</option>
              <option>Data</option>
              <option>Security</option>
            </select>
            <input
              className="contact_input"
              type={"text"}
              placeholder="Some extra info"
              value={enteredData.aboutCompany}
              onChange={(e) => {
                setEnteredData({
                  ...enteredData,
                  aboutCompany: e.target.value,
                });
              }}
            />
          </div>
          <br></br>
          <br></br>
          <div style={{ display: "flex" }}>
            <div
              style={{
                height: "50px",
                width: "150px",
                background: "#000",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFCF4A",
                fontSize: "1em",
                fontWeight: "1000",
                marginRight: "10px",
              }}
              className="submit_btn"
              onClick={() => {
                handleSubmit();
              }}
            >
              UPDATE
            </div>
            <div
              style={{
                height: "50px",
                width: "150px",
                background: "#000",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFCF4A",
                fontSize: "1em",
                fontWeight: "1000",
              }}
              className="submit_btn"
              onClick={() => {
                setIsApply(false);
                setSelected(null);
              }}
            >
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditJobModal;

import React, { useState } from "react";
import { BiBriefcaseAlt2, BiLoader } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { FaHourglassHalf } from "react-icons/fa";
import ApplyModal from "./components/ApplyModal";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "./Firebase/config";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
function JobPage() {
  const location = useLocation();
  const [allJobs, setAllJobs] = useState(null);
  useEffect(() => {
    onSnapshot(collection(db, "jobs"), (snap) => {
      setAllJobs(snap.docs.map((doc) => doc.data()));
    });
  }, []);
  const [isApply, setIsApply] = useState(false);

  const [jobData, setJobData] = useState(null);
  useEffect(() => {
    console.log(location.search);
    onSnapshot(doc(db, "jobs", location.search.slice(1, 100)), (snap) => {
      setJobData(snap.data());
    });
  }, []);

  if (!jobData) {
    return (
      <div className="home_container">
        <BiLoader />
      </div>
    );
  }
  return (
    <div className="home_container">
      {isApply && (
        <ApplyModal
          setIsApply={setIsApply}
          jobId={location.search.slice(1, 100)}
        />
      )}
      <h1 className="bigFlashy">{jobData?.title}</h1>
      <div
        style={{
          width: "100%",
          display: window.innerWidth > 600 ? "flex" : "block",
        }}
      >
        <div style={{ flex: "0.8" }}>
          <h3 style={{ color: "#fff", maxWidth: "800px" }}>{jobData?.desc}</h3>
          <div
            style={{
              display: "flex",
              width: "fit-content",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginRight: "40px" }}>
              <h4 style={{ color: "#fff" }}>
                <BiBriefcaseAlt2 style={{ marginRight: "10px" }} />
                {jobData?.category}
              </h4>
              <h4 style={{ color: "#fff" }}>
                <HiLocationMarker style={{ marginRight: "10px" }} />
                {jobData?.location}
              </h4>
            </div>
            <div>
              <h4 style={{ color: "#fff" }}>
                <BsFillCalendarWeekFill style={{ marginRight: "10px" }} />
                {jobData?.exp}
              </h4>
              <h4 style={{ color: "#fff" }}>
                <FaHourglassHalf style={{ marginRight: "10px" }} />
                {jobData?.closeDate}
              </h4>
            </div>
          </div>
        </div>
        <div style={{ flex: "0.2" }}>
          <div style={{ display: "block" }}>
            <div
              style={{
                flex: "0.5",
                border: "1px solid #fff",
                color: "#fff",
                padding: "20px 40px",
                textAlign: "center",
                margin: "15px",
                borderRadius: "50vh",
                fontFamily: "thunder",
                fontSize: "2em",
              }}
              className="submit_btn"
              onClick={() => {
                setIsApply(true);
              }}
            >
              APPLY
            </div>
            <div
              style={{
                flex: "0.5",
                border: "1px solid #fff",
                color: "#fff",
                padding: "20px 40px",
                textAlign: "center",
                margin: "15px",
                borderRadius: "50vh",
                fontFamily: "thunder",
                fontSize: "2em",
              }}
              className="submit_btn"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://digitalpeople.netlify.app/job?${jobData?.jobId}`
                );
                alert("Link is copied!");
              }}
            >
              SHARE THIS JOB
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <h1 className="bigFlashy" style={{ color: "#385EFC" }}>
        QUALIFICATIONS
      </h1>
      <ul>
        {jobData?.qual.map((data) => {
          return <li>{data}</li>;
        })}
      </ul>
      <br></br>
      <br></br>
      <h1 className="bigFlashy" style={{ color: "#385EFC" }}>
        RESPONSIBILITIES
      </h1>
      <ul>
        {jobData?.resp.map((data) => {
          return <li>{data}</li>;
        })}
      </ul>
      <h1 className="bigFlashy" style={{ color: "#385EFC" }}>
        SOME EXTRA INFO
      </h1>
      <h3 style={{ color: "#fff", maxWidth: "800px" }}>
        {jobData?.aboutCompany !== "" ? jobData?.aboutCompany : "no data"}
      </h3>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1 className="bigFlashy">MIGHT ALSO BE INTERESTED IN?</h1>
      <div className="projects" id="#projects" style={{ paddingTop: "0px" }}>
        <div
          style={{ display: "flex", overflow: "scroll", margin: "40px 0px" }}
        >
          {allJobs &&
            allJobs.map((data) => {
              return (
                <div
                  style={{
                    height: "200px",
                    minWidth: "220px",
                    borderRadius: "10px",
                    background: "#fff",
                    margin: "15px",
                    position: "relative",
                    padding: "15px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div
                      style={{
                        background: "#FFCF4A",
                        width: "fit-content",
                        color: "#000",
                        padding: "5px 18px",
                        borderRadius: "50vh",
                      }}
                    >
                      {data.jobId.slice(0, 5)}
                    </div>
                    <h2 style={{ fontSize: "1.3em", marginBottom: "10px" }}>
                      {data.title}
                    </h2>
                    <h4 style={{ margin: "0" }}>Contract in {data.location}</h4>
                  </div>
                  <Link to={`/job?${data.jobId}`}>
                    <h5 style={{ margin: "0" }}>Learn more</h5>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default JobPage;

import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { v4 } from "uuid";
import {
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../Firebase/config";
import { useEffect } from "react";
function ApplicationsModal({ selected, setSelected }) {
  const [allApplications, setAllApplications] = useState(null);
  useEffect(() => {
    onSnapshot(
      query(
        collection(db, "applications"),
        where("jobId", "==", selected.jobId)
      ),
      (snap) => {
        setAllApplications(snap.docs.map((doc) => doc.data()));
      }
    );
  }, []);
  return (
    <div
      className="apply_modal_container"
      style={{ overflow: "scroll" }}
      onClick={() => {
        setSelected(null);
      }}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "60%",
            height: "max-content",
            borderRadius: "20px",
            background: "#FFCF4A",
            padding: "80px 50px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h3 className="bigFlashy" style={{ color: "#000" }}>
            All Applications
          </h3>
          {allApplications &&
            allApplications.map((data, i) => {
              return (
                <div
                  style={{
                    height: "max-content",
                    minWidth: "220px",
                    borderRadius: "10px",
                    background: "#385EFC",
                    margin: "15px",
                    position: "relative",
                    padding: "25px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#FFCF4A",
                      width: "fit-content",
                      color: "#000",
                      padding: "3px 10px",
                      borderRadius: "50vh",
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                    }}
                  >
                    {i + 1}
                  </div>
                  <div style={{ marginRight: "40px" }}>
                    <h4 style={{ color: "#fff" }}>First Name: {data?.fname}</h4>
                    <h4 style={{ color: "#fff" }}>Last Name: {data?.lname}</h4>
                  </div>
                  <div>
                    <h4 style={{ color: "#fff" }}>
                      Qualification: {data?.qualification}
                    </h4>
                    <h4 style={{ color: "#fff" }}>
                      Experiance: {data?.pastexp}
                    </h4>
                  </div>
                  <div>
                    <h4 style={{ color: "#fff" }}>
                      Contact no.:{data?.contactno}
                    </h4>
                    <h4 style={{ color: "#fff" }}>Email: {data?.email}</h4>
                  </div>
                  {data.file !== "" && (
                    <div
                      style={{
                        height: "40px",
                        width: "150px",
                        background: "#000",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#FFCF4A",
                        fontSize: "1em",
                        fontWeight: "1000",
                        marginBottom: "10px",
                      }}
                    >
                      <a
                        href={data?.file}
                        style={{
                          color: "inherit",
                        }}
                        target="_blank"
                      >
                        View CV
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
          <div style={{ display: "flex" }}>
            <div
              style={{
                height: "50px",
                width: "150px",
                background: "#000",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFCF4A",
                fontSize: "1em",
                fontWeight: "1000",
              }}
              onClick={() => {
                setSelected(null);
              }}
            >
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationsModal;

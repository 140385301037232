import { useState } from "react";
import About from "./About";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import JobPage from "./JobPage";
import HomePage from "./HomePage";
import AllJobs from "./AllJobs";
import ScrollToTop from "./components/ScrollToTop";
import Admin from "./Admin";
import CookiePopup from "./components/CookiePopup";
function App() {
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  return (
    <div className="App">
      <CookiePopup />
      <Router>
        <ScrollToTop />
        <Header setIsAboutVisible={setIsAboutVisible} />
        <div>
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="/job" element={<JobPage />}></Route>
            <Route path="/alljobs" element={<AllJobs />}></Route>
            <Route path="/aaddmmiinn" element={<Admin />}></Route>
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

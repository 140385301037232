import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`hamburger-menu ${isOpen ? "open" : ""} `}>
      <button className="hamburger-button" onClick={handleToggle}>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
      </button>
      {isOpen && (
        <div
          className="glass  "
          onClick={() => {
            setIsOpen(false);
          }}
          style={{
            zIndex: "999999999",
            height: "100vh",
            width: "100vw",
            position: "fixed",
            top: "0",
            left: "0",
          }}
        ></div>
      )}
      {isOpen && (
        <div className="menu  " style={{ zIndex: "9999999999" }}>
          <Link
            to="/"
            className="tab_link"
            style={{
              fontSize: "17px",
              margin: "5px 13px",
              display: "flex",
              flexDirection: "column",
              color: `${
                location.pathname === "/" ||
                location.pathname === "/home" ||
                location.pathname === "/class"
                  ? "#007bff"
                  : "#404040"
              }`,
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Home
          </Link>
          <Link
            to="/#Services"
            className="tab_link"
            style={{
              fontSize: "17px",
              margin: "5px 13px",
              display: "flex",
              flexDirection: "column",
              color: `${
                location.pathname === "/howitworks" ? "#007bff" : "#404040"
              }`,
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Services
          </Link>
          <Link
            to="/#ourWork"
            className="tab_link"
            style={{
              fontSize: "17px",
              margin: "5px 13px",
              display: "flex",
              flexDirection: "column",
              color: `${
                location.pathname === "/contact" ? "#007bff" : "#404040"
              }`,
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            OurWork
          </Link>
          <Link
            to="/#aboutus"
            className="tab_link"
            style={{
              fontSize: "17px",
              margin: "5px 13px",
              display: "flex",
              flexDirection: "column",
              color: `${location.pathname === "/blog" ? "#007bff" : "#404040"}`,
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            About us
          </Link>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;

import React from "react";
import CookieConsent from "react-cookie-consent";
import pointer from "../raw/yellow-arrow-pointer.svg";

const CookiePopup = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      enableDeclineButton
      cookieName="myCookie"
      style={{ background: "#2B373B" }}
      buttonStyle={{
        background: "#ffcf4a",
        color: "white",
        cursor: `url("${pointer}"), auto`,
        color: "#000",
      }}
      declineButtonStyle={{
        background: "#ff5252",
        color: "white",
        cursor: `url("${pointer}"), auto`,
        color: "#000",
      }}
      expires={365}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
};

export default CookiePopup;

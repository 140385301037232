// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCu8_7UW3dQEbWCsmVJqW9gTvru3S56XHs",
  authDomain: "stock-app-2a74c.firebaseapp.com",
  projectId: "stock-app-2a74c",
  storageBucket: "stock-app-2a74c.appspot.com",
  messagingSenderId: "830459873792",
  appId: "1:830459873792:web:a54f09f85c6e55975666e5",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
export { auth };
const db = getFirestore(firebaseApp);
export { db };
const storage = getStorage(firebaseApp);
export { storage };
const database = getDatabase(firebaseApp);
export { database };

export { firebaseApp };

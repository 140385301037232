import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(location.hash);
    if (location.hash === "#aboutus") {
      const element = document.getElementById("aboutus");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // You can use 'auto' for immediate scroll
      } else {
      }
    } else if (location.hash === "#Services") {
      const element = document.getElementById("Services");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // You can use 'auto' for immediate scroll
      } else {
      }
    } else if (location.hash === "#ourWork") {
      const element = document.getElementById("whyus");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // You can use 'auto' for immediate scroll
      } else {
      }
    }
    console.log("ele not f");
  }, [location]);

  return null;
}
